<template>
  <div class="pages-table">
    <template v-if="getFormattedListOfPages.length">
      <app-text-header
        :number="3"
        classes="pl-6 mb-4 font-weight-regular">
        <slot name="header" />
      </app-text-header>
      <CollectionsTable
        :collections-list="getFormattedListOfPages"
        group="wsPage"
        hide-column-members
        @click-action-menu="onClickActionMenu({
          ...$event,
          group: 'wsPage',
          actionsConditions: [{
            name: 'PageFollow',
            kind: ['default'],
          }],
          showActions: true,
          kind: 'default',
        })" />
      <Actions
        :show-activator="false"
        :item.sync="selectedItem"
        v-bind="actionsProps"
        :position-x="positionX"
        :position-y="positionY"
        :value.sync="showActionsCard"
        @manageActions="manageActions" />
    </template>

    <AppEmptyList
      v-else
      from="pages" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ManageCollectionsActions from '@/mixins/ManageCollectionsActions';
import AppActionDotsMenu from '@/mixins/AppActionDotsMenu';
// components
import Actions from '@/components/Collections/CollectionsActions';
import AppTextHeader from '@/components/App/AppTextHeader';
import CollectionsTable from '@/components/Collections/CollectionsTable';
export default {
  name: 'PagesTable',
  components: {
    Actions,
    AppTextHeader,
    CollectionsTable,
    AppEmptyList: () => import('@/components/App/AppEmptyList'),
  },
  mixins: [
    AppActionDotsMenu,
    ManageCollectionsActions,
  ],
  data() {
    return {
      actionsProps: {
      },
    };
  },
  computed: {
    ...mapGetters('MyPages', ['getFormattedListOfPages']),
  },
  methods: {
    onClickActionMenu({ item, event, group = null, actionsConditions = [], showActions = false, kind = '' }) {
      this.setMenuItem({
        item,
        event,
      });
      this.actionsProps = {
        group,
        actionsConditions,
        showActions,
        kind,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.pages-table {
  display: grid;
  gap: 60px;
}
</style>
