<template>
  <div class="pages-gallery">
    <template v-if="getPagesList.length">
      <app-text-header
        :number="3"
        classes="pl-6 mb-4 font-weight-regular">
        <slot name="header" />
      </app-text-header>
      <ListingImageItem
        :libraries-list="getPagesList"
        :show-counter="false"
        @navigate="navigateNext($event, 'wsPage')"
        v-on="$listeners">
        <template #actions="{ item }">
          <AppKebabActions>
            <PageFollowWithWsSelection
              v-if="workspaceSelectorOnPages"
              :item="item" />
            <app-icon-btn
              v-else-if="isFollowed(item.id)"
              icon-height="15"
              show-tooltip
              bottom
              icon-name="mdi-account-multiple-minus"
              @click="onUnFollowPage(item)">
              Unfollow
            </app-icon-btn>
            <SuggestWorkspacePageDialog
              v-if="showUpdatesToWorkspaceGuests"
              :workspace-page-id="item.id"
              :page-workspace-id="item.workspaceId"
              class="mr-2"
              is-icon
              show-in-kebab-action />
          </AppKebabActions>
        </template>
      </ListingImageItem>
    </template>

    <AppEmptyList
      v-else
      from="pages" />
  </div>
</template>
<script>
import {
  mapGetters, mapActions,
} from 'vuex';
import NavigateToCollection from '@/mixins/NavigateToCollection';
import AppKebabActions from '@/components/App/AppKebabActions/index.vue';
import AppTextHeader from '@/components/App/AppTextHeader';
import ListingImageItem from '@/components/Listing/ListingImageItem';
import PageFollowWithWsSelection from '@/components/Collections/CollectionsActions/CollectionsActionsComponent/PageFollow/PageFollowWithWsSelection';
import SuggestWorkspacePageDialog from '@/components/App/AppSuggestDialog/SuggestWorkspacePageDialog';
export default {
  name: 'PagesGallery',
  components: {
    AppTextHeader,
    ListingImageItem,
    AppKebabActions,
    PageFollowWithWsSelection,
    SuggestWorkspacePageDialog,
    AppEmptyList: () => import('@/components/App/AppEmptyList'),
  },
  mixins: [NavigateToCollection],
  computed: {
    ...mapGetters('MyPages', ['getFormattedListOfPages', 'getIdsOfMyPages']),
    ...mapGetters({
      workspaceSelectorOnPages: 'FeatureFlags/workspaceSelectorOnPages',
      showUpdatesToWorkspaceGuests: 'FeatureFlags/showUpdatesToWorkspaceGuests',
    }),
    ...mapGetters('Workspace', ['getActiveWorkspaceId']),
    getPagesList() {
      if (!this.showUpdatesToWorkspaceGuests) {
        const activeWsId = this.getActiveWorkspaceId;
        return this.getFormattedListOfPages.filter((page) => page.id != activeWsId);
      }
      return this.getFormattedListOfPages;
    },
  },
  methods: {
    ...mapActions('MyPages', ['unfollowPage', 'followPage']),
    isFollowed(pageId = '') {
      return this.getIdsOfMyPages.includes(pageId);
    },
    async onUnFollowPage({ id = '', workspaceId = '' }) {
      await this.unfollowPage({
        pageId: id,
        pageWorkspaceId: workspaceId,
      });
    },
  },
};
</script>
<style scoped lang="scss">
.pages-gallery {
  display: grid;
  gap: 60px;

  &__content {
    padding-top: 50px;
    display: grid;
    gap: 36px;
    width: 100%;
    margin: 0 auto;

    .main-blocks {
      display: flex;
      justify-content: center;
    }
  }
}
.secondary-blocks {
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(218px, 1fr) );
  gap: 16px 24px;
}
</style>
