var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pages-table"},[(_vm.getFormattedListOfPages.length)?[_c('app-text-header',{attrs:{"number":3,"classes":"pl-6 mb-4 font-weight-regular"}},[_vm._t("header")],2),_c('CollectionsTable',{attrs:{"collections-list":_vm.getFormattedListOfPages,"group":"wsPage","hide-column-members":""},on:{"click-action-menu":function($event){return _vm.onClickActionMenu(Object.assign({}, $event,
        {group: 'wsPage',
        actionsConditions: [{
          name: 'PageFollow',
          kind: ['default'],
        }],
        showActions: true,
        kind: 'default'}))}}}),_c('Actions',_vm._b({attrs:{"show-activator":false,"item":_vm.selectedItem,"position-x":_vm.positionX,"position-y":_vm.positionY,"value":_vm.showActionsCard},on:{"update:item":function($event){_vm.selectedItem=$event},"update:value":function($event){_vm.showActionsCard=$event},"manageActions":_vm.manageActions}},'Actions',_vm.actionsProps,false))]:_c('AppEmptyList',{attrs:{"from":"pages"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }