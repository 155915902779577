<template>
  <div class="my-page">
    <div class="px-9 d-flex justify-space-between">
      <app-breadcrumbs
        :push-back-url="breadcrumbItems.pushBackUrl"
        :breadcrumbs-list="breadcrumbItems.breadcrumbs" />
      <AppSwitcherToListing />
    </div>
    <v-col
      v-if="!isActiveUserGuestInWorkspace || showUpdatesToWorkspaceGuests"
      class="mt-9 mb-12 col library-page-container">
      <component
        :is="isListingView ? 'PagesGallery' : 'PagesTable'">
        <template v-slot:header>
          Pages
        </template>
      </component>
    </v-col>
  </div>
</template>
<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
} from 'vuex';
import { getFullLinkForWorkspaces } from '@/utils';
import ToggleViewCondition from '@/mixins/ToggleViewCondition';
import PagesGallery from '@/views/MyPage/Views/PagesGallery.vue';
import PagesTable from '@/views/MyPage/Views/PagesTable.vue';
// components
import AppBreadcrumbs from '@/components/App/AppBreadcrumbs';
import AppSwitcherToListing from '@/components/App/AppListingElements/AppSwitcherToListing';
export default {
  name: 'MyPageView',
  components: {
    AppBreadcrumbs,
    AppSwitcherToListing,
    PagesGallery,
    PagesTable,
  },
  mixins: [ToggleViewCondition],
  computed: {
    ...mapGetters('Workspace', ['isActiveUserGuestInWorkspace']),
    ...mapGetters('FeatureFlags', ['showUpdatesToWorkspaceGuests']),
    isListingView() {
      return this.toggleViewCondition('librariesRelatedPages');
    },
    breadcrumbItems() {
      const pushBackUrl = getFullLinkForWorkspaces('/');
      return {
        breadcrumbs: [
          {
            text: 'Home',
            disabled: false,
            to: pushBackUrl,
          },
          {
            text: 'My pages',
            disabled: true,
          },
        ],
        pushBackUrl,
      };
    },
  },
  async created() {
    this.getListOfPages();
  },
  async updated() {
    await this.$nextTick();
    this.setUploadingSpinner(false);
  },
  methods: {
    ...mapMutations(['setUploadingSpinner']),
    ...mapActions({
      getListOfPages: 'MyPages/getListOfPages',
    }),
  },
};
</script>
